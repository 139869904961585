import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function SectionSummary() {
  return (
    <>
      <div className="section section-dark section-summary">
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-layout-11" />
                </div>
                <div className="description">
                  <h4 className="info-title">Vision</h4>
                  <p>
                    즐겁고 함께하는 투자생활
                    <br />
                    토지와 건물 그리고 공간의 자산가치 활용 극대화
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-tile-56" />
                </div>
                <div className="description">
                  <h4 className="info-title">Mission</h4>
                  <p>
                    함께 키우고 성장하는 투자생활
                    <br /> 국경없는 경제 활동 <br />
                    저평가된 토지와 건물의 재탄생 <br />
                    내일의 중심에 서서 미래를 건설
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-paper" />
                </div>
                <div className="description">
                  <h4 className="info-title">Values</h4>
                  <p>
                    Dream Big <br />
                    선한 영향력 <br />
                    신뢰와 투명성 <br /> 성과 <br /> 책임감과 용기
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
