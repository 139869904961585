import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionTeamEng() {
  return (
    <>
      <div className="section section-team cd-section" id="teams">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Team</h2>
              <h5 className="description">Our One Team</h5>
            </Col>
          </Row>
          <div className="space-top" />
          <Row>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/sophie.png")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05EHCM0VGV-01056fd58eed-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Sophie Choi</b>
                      </CardTitle>
                      <h6 className="card-category">CEO</h6>
                      <p className="card-description">
                        <b>Education</b>
                        <br />
                        Cornell University MBA
                        <br />
                        <br />
                        <b>Work Experience</b>
                        <br />
                        OneCup | Chief Executive Officer
                        <br />
                        Toss I Business Strategy Lead, Toss & Operational
                        Innovation Lead,
                        <br />
                        Toss Payments (HQ Executive)
                        <br />
                        Merck Group USA | Global Product/Market Lead (HQ
                        Executive)
                        <br />
                        Corning USA | Global Product Lead, Integration M&A Lead
                        <br />
                        Samsung | M&A , Strategy
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) =>
                            e.preventDefault(
                              window.open(
                                "https://www.linkedin.com/in/sojungchoi/"
                              )
                            )
                          }
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/jh_pink.png")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05FV3VDUSD-03f04606693e-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Blair Lee</b>
                      </CardTitle>
                      <h6 className="card-category">Operation Lead</h6>
                      <p className="card-description">
                        <b>Education</b>
                        <br />
                        Hankuk University of Foreign Studies, Seoul
                        <br />
                        <br />
                        <b>Work Experience</b>
                        <br />
                        Foremost | Finance Lead <br />
                        ZARI/ZARI Vietnam | Strategy Lead, Head of Vietnam
                        <br />
                        HIDCM | Project Lead <br />
                        Dongsoong Art Center | PR Lead
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) =>
                            e.preventDefault(
                              window.open(
                                "https://www.linkedin.com/in/realizer21c/"
                              )
                            )
                          }
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          // src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                          src="https://ca.slack-edge.com/T056CFL9XLY-U05654ARB25-5f938ca5b831-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Jae Jun Yoon</b>
                      </CardTitle>
                      <h6 className="card-category">Strategy Lead</h6>
                      <p className="card-description">
                        <b>Education</b>
                        <br />
                        POSTECH System Engineering
                        <br />
                        <b>Work Experience</b>
                        <br />
                        KIF | CEO
                        <br />
                        MEPAS | CEO/CSO
                        <br />
                        H Lab | Head of Strategy
                        <br />
                        Moove78 | R&D Strategy Manager
                        <br />
                        Germany DFKI | Researcher
                        <br />
                        POSTECH smart factory research center | Researcher
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) =>
                            e.preventDefault(
                              window.open(
                                "https://www.linkedin.com/in/realizer21c/"
                              )
                            )
                          }
                        >
                          <i className="fa fa-linkedin" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/team_brighte.jpg")}
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Brighte Yun</b>
                      </CardTitle>
                      <h6 className="card-category">Development Lead</h6>
                      <p className="card-description">
                        <b>Work Experience</b>
                        <br />
                        +20 years Full Stack Developer
                        <br />
                        Bespin Global | Team Lead
                        <br />
                        Goodnet | Team Lead
                        <br />
                        UART | Tech Lead
                        <br />
                        N2M | Tech Lead
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/nb.png")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U0561ED53PT-g03976e13de0-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Ki Hwan Nam</b>
                      </CardTitle>
                      <h6 className="card-category">Data Scientist</h6>
                      <p className="card-description">
                        <b>Education</b>
                        <br />
                        KAIST Physics PhD
                        <br />
                        <br />
                        <b>Work Experience</b>
                        <br />
                        GIST | Commissioned Researcher
                        <br />
                        Creativity Corp | Data Scientist
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/sb.jpg")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U055KT9BD9B-gf5a1280451e-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Sang Hur</b>
                      </CardTitle>
                      <h6 className="card-category">Global Biz Dev Lead</h6>
                      <p className="card-description">
                        <b>Education</b>
                        <br />
                        Cornell MBA and Master’s in Labor Relations
                        <br />
                        <br />
                        <b>Work Experience</b>
                        <br />
                        MISO (O2O Platform) | Executive
                        <br />
                        US Nuance Communication (AI) | M&A, Divestiture
                        <br />
                        US Amazon | Principal M&A Program Manager
                        <br />
                        US Corning | M&A Integration Lead
                        <br />
                        Tesco | APAC Regional Expansion Manager
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/hyun.png")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05ELAWLCHY-ef5a0b26dba1-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Tae Hyun Kang</b>
                      </CardTitle>
                      <h6 className="card-category">Product Owner</h6>
                      <p className="card-description">
                        <b>Work Experience</b>
                        <br />
                        Leamcoon | CTO
                        <br />
                        Brecobean | Team Lead
                        <br />
                        Mediaum | Web/Blockchain Developer
                        <br />
                        Allstech | Embedded Developer
                      </p>
                      <CardFooter className="pull-left">
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/team_tony.jpg")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05EHCM0VGV-01056fd58eed-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Tony Youn</b>
                      </CardTitle>
                      <h6 className="card-category">Marketing/Sales Lead</h6>
                      <p className="card-description">
                        <b>Work Experience</b>
                        <br />
                        Merck Group | Thinfilm Global SOD marketing manager /
                        Global customer application support team Korea lead
                        <br />
                        Samsung Electronics | Key Sales Account Manage
                        <br />
                        Fujifilm Korea | Technical sales
                        <br />
                        Chisso Korea | Sales / Engineer
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) =>
                            e.preventDefault(
                              window.open(
                                "https://www.linkedin.com/in/sojungchoi/"
                              )
                            )
                          }
                        >
                          <i className="fa fa-linkedin" />
                        </Button> */}
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/team_dahyun.jpg")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05EHCM0VGV-01056fd58eed-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Da Hyoun Kim</b>
                      </CardTitle>
                      <h6 className="card-category">AI Engineer</h6>
                      <p className="card-description">
                        <b>Work Experience</b>
                        <br />
                        Deepnoid | Assistant Research Engineer at the Medical AI
                        <br />
                        Socsoft | Research Engineer
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) =>
                            e.preventDefault(
                              window.open(
                                "https://www.linkedin.com/in/sojungchoi/"
                              )
                            )
                          }
                        >
                          <i className="fa fa-linkedin" />
                        </Button> */}

                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/faces/team_seoa.jpg")}
                          // src="https://ca.slack-edge.com/T056CFL9XLY-U05EHCM0VGV-01056fd58eed-512"
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="6">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">
                        <b>Seo A Lee</b>
                      </CardTitle>
                      <h6 className="card-category">UX/UI Designer</h6>
                      <p className="card-description">
                        <b>Work Experience</b>
                        <br />
                        +7 years UX/UI Designer
                        <br />
                        Nssoft | UX/UI Lead
                        <br />
                        D.Graph | UX/UI Lead
                        <br />
                        Inexkr | UX/UI Lead
                        <br />
                        Seavantage | UX/UI & Product Designer
                        <br />
                      </p>
                      <CardFooter className="pull-left">
                        {/* <Button
                          className="btn-just-icon btn-link mr-1"
                          color="facebook"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-just-icon btn-link"
                          color="google"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-google-plus" />
                        </Button> */}
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionTeamEng;
