import React from "react";

// reactstrap components

// core components
import ColorNavbarEng from "components/Navbars/ColorNavbarEng.js";
import PresentationHeaderEng from "components/Headers/PresentationHeaderEng.js";
import FooterBlackEng from "components/Footers/FooterBlackEng.js";
// sections for this page
import SectionSummaryEng from "./presentation-sections/SectionSummaryEng.js";
import SectionComponentsEng from "./presentation-sections/SectionComponentsEng.js";

import SectionContentEng from "./presentation-sections/SectionContentEng.js";
import SectionSharingEng from "./presentation-sections/SectionSharingEng.js";
import SectionTeamEng from "./sections-sections/SectionTeamEng.js";

import SectionFeatureEng from "./sections-sections/SectionFeatureEng.js";

function PresentationEng() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbarEng />
      <PresentationHeaderEng />
      <SectionSummaryEng />
      <SectionComponentsEng />
      <SectionContentEng />
      <SectionFeatureEng />
      <SectionTeamEng />
      <SectionSharingEng />
      <FooterBlackEng />
    </>
  );
}

export default PresentationEng;
