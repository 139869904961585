import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionFeatureEng() {
  return (
    <>
      <div className="section section-feature cd-section" id="features">
        {/* ********* FEATURES 5 ********* */}
        <div
          className="features-5 section-dark"
          style={
            {
              // backgroundColor: "#151f20",
              //   backgroundImage:
              //     "url(" +
              //     require("assets/img/sections/the-how-photographer.jpg") +
              //     ")",
            }
          }
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Product Offerings: System Level
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-atom" />
                  </div>
                  <h4 className="title">
                    Automated Valuation <br />
                    Forecasting System
                  </h4>
                  <p>Curating service based on customer profile</p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">
                    User Friendly System <br />
                    Using Gov’t Data
                  </h4>
                  <p>System optimization using gov’t data</p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-paper" />
                  </div>
                  <h4 className="title">Diversity Portfolio</h4>
                  <p>
                    Maximize Profit and diverse portfolio through in-early land
                    development <br />
                    Real Estate + IP Sourcing
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-time-alarm" />
                  </div>
                  <h4 className="title">One Click Easy Investment System</h4>
                  <p>Anyone can invest in 3 mins with 1-click</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
        {/* ********* FEATURES 5 ********* */}
        <div
          className="features-5 section-dark"
          // className="features-5 section-image"
          // style={{
          //   backgroundImage:
          //     "url(" +
          //     require("assets/img/sections/the-how-photographer.jpg") +
          //     ")",
          // }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Product Offerings: Process Level
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-atom" />
                  </div>
                  <h4 className="title">Risk Management</h4>
                  <p>Protect customer’s data and assets</p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">Digital Process</h4>
                  <p>
                    Minimize complicated process and deploy online-digital
                    process through smart contract
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-paper" />
                  </div>
                  <h4 className="title">
                    Carbon Neatral and <br />
                    Zero Energy Process
                  </h4>
                  <p>
                    Buy old building and do a green remodeling to reduce the
                    carbon footprint
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">
                    Time-sharing <br />
                    Rental Process
                  </h4>
                  <p>
                    Maximizing STO Rental Yield
                    <br />
                    by Time-sharing reservation service
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
      </div>
    </>
  );
}

export default SectionFeatureEng;
