import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponentsEng() {
  return (
    <>
      <div className="section section-components section-dark">
        <Row>
          <Col lg="6" md="12">
            <div className="image-container">
              <img
                alt="..."
                className="components-macbook"
                src={require("assets/img/presentation-page/laptop-value-add.png")}
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/img/presentation-page/single-value-add.png")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={require("assets/img/presentation-page/share-btn.png")}
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={require("assets/img/presentation-page/business-bangbea.jpg")}
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={require("assets/img/presentation-page/seoul.jpg")}
              />
              {/* <img
                alt="..."
                className="social-img"
                src={require("assets/img/presentation-page/social-row.png")}
              /> */}
              {/* <img
                alt="..."
                className="pin-btn-img"
                src={require("assets/img/presentation-page/pin-btn.png")}
              /> */}
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">Ultra-wealthy(超富裕) Portfolios</h3>
              <h6 className="category">
                ONECUP, No.1 in Creating Ultra-wealthy Portfolios
              </h6>
              <h5 className="description">
                ONECUP is creating an era in which anyone can invest by
                converting any asset into securities in the world.
                <br />
                ONECUP PRO., which provides a solution for fractional real
                estate investing, will increase the value of space to be
                utilized and help investors form assets through the investment
                processes without barriers.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponentsEng;
