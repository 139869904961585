import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="7">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/presentation-page/ipad-content-onecup-home.png")}
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    style={{ width: "auto" }}
                    src={require("assets/img/presentation-page/oncup-pro-app-1.png")}
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    style={{ width: "auto" }}
                    src={require("assets/img/presentation-page/oncup-pro-app-2.png")}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">OneCup PRO.</h3>
                  <h6 className="category">
                    Creating a Cup of Wealth
                    <br />
                    Creating Your Dream Cup
                  </h6>
                  <h5 className="description">
                    원컵 프로는 자산 미래가치 시스템을 통한 고객 성향에 따른
                    맞춤형 부동산 조각투자 서비스 입니다.
                    <br /> 개인 투자가 어려운 랜드마크 부동산의 개발부터, 매매,
                    임대를 3분내 1만원 단위로 투자하고, 4가지의 자산 가치 예측
                    시스템으로 수익 극대화를 돕는 서비스 입니다.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
