/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlackEng() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                {/* <li>OneCup Co.,LTD</li> */}
                <li>Floor 8, Gangnamdaero 341, Samwonbuilding, Seoul</li>
                <li>
                  Floor 4, Pangyo-ro 289beon-gil, Bundang-gu. Seongnam-Si,
                  Gyeonggi-do, Republic of Korea
                </li>
                {/* <li>Copyright by OneCup Co.,Ltd. All Rights Reserved.</li> */}
                {/* <li>
                  <a
                    href="https://www.creative-tim.com?ref=pkpr-black-footer"
                    target="_blank"
                    className="mr-1"
                  >
                    Creative Tim
                  </a>
                </li>
                <li>
                  <a
                    href="http://blog.creative-tim.com/?ref=pkpr-black-footer"
                    target="_blank"
                    className="mr-1"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.creative-tim.com/license?ref=pkpr-black-footer"
                    target="_blank"
                  >
                    Licenses
                  </a>
                </li> */}
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                Copyright by OneCup Co.,Ltd. All Rights Reserved.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlackEng;
