import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionFeature() {
  return (
    <>
      <div className="section section-feature cd-section" id="features">
        {/* ********* FEATURES 5 ********* */}
        <div
          className="features-5 section-dark"
          style={
            {
              // backgroundColor: "#151f20",
              //   backgroundImage:
              //     "url(" +
              //     require("assets/img/sections/the-how-photographer.jpg") +
              //     ")",
            }
          }
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Product Offerings: System Level
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-atom" />
                  </div>
                  <h4 className="title">자동화 자산 평가 예측 시스템</h4>
                  <p>
                    고객별 맞춤형 자산 추천 서비스 <br />
                    고객 Preference를 분석하여 안정성과 수익성에 맞는 투자 자산
                    자동 매칭 서비스
                    <br /> AI/ML 기반 AVM(Automated Valuation Model)
                    <br />
                    자산 담보 가치 측정 모델링
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">
                    공공데이터를 활용한
                    <br /> User Friendly 시스템
                  </h4>
                  <p>
                    공공데이터 활용/연계를 통한 시스템 고도화
                    <br />
                    당사 서비스와 협업 및 모델링에 활용 <br />
                    한국자산관리공사의 온비드 시스템과 연계
                    <br />
                    한국국토개발공사 포털과 연계 <br />
                    토지 및 건물의 조각투자 기회 제공
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-paper" />
                  </div>
                  <h4 className="title">Diversity Portfolio</h4>
                  <p>
                    개인투자자의 극초기 개발 부동산 참여를 통한 투자영역 확대 및
                    수익률 극대화
                    <br />
                    주거용, 상업용 부동산 뿐만 아니라 토지개발부터 건물매매,
                    임대까지 수익을 다같이 Sharing
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-time-alarm" />
                  </div>
                  <h4 className="title">One Click Easy 투자 시스템</h4>
                  <p>
                    누구나 3분안에 원클릭으로 투자가 가능
                    <br />
                    STO (Security Token Offering) 활용한 실시간 투자 시스템
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
        {/* ********* FEATURES 5 ********* */}
        <div
          className="features-5 section-dark"
          // className="features-5 section-image"
          // style={{
          //   backgroundImage:
          //     "url(" +
          //     require("assets/img/sections/the-how-photographer.jpg") +
          //     ")",
          // }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Product Offerings: Process Level
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-atom" />
                  </div>
                  <h4 className="title">Risk Management</h4>
                  <p>
                    고객 데이터 보호 및 자산 보호
                    <br />
                    자산별 Risk , 유동성 Risk 분석 <br />
                    보안 Risk 관리 및 프로세스 도입
                    <br />
                    AML(Anti Money Laundering) 리스크 관리 <br />
                    개인정보 및 민감정보 데이터 보호
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">Digital Process</h4>
                  <p>
                    비대면 프로세스를 통한 빠르고 정확한 투자 진행
                    <br />
                    부동산 거래의 복잡성을 간소화 <br />
                    One Stop Buying Process: 비대면 프로세스로 향후 담보 대출,
                    보험 등 파트너사와 연계 서비스 진행
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-paper" />
                  </div>
                  <h4 className="title">Pricing 정보 공개 기능</h4>
                  <p>
                    고객 중심의 투명하고 쉬운 거래가격 산출
                    <br />
                    매매시 차액거래 예측 정보 공개
                    <br />
                    임대시 임대수익 예측 정보 공개 주변
                    <br />
                    시세, 건축연한, 실거래가 등의 정보 공개
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">시분할 공간임대 Process</h4>
                  <p>
                    STO 임대 수익률 극대화
                    <br />
                    임대시 공간 가치 극대화를 위한 시분할 예약 서비스
                    <br />
                    중장기 예약: 리모델링 서비스로 Value Add <br />
                    단기 예약: 피크타임 분석에 따라 업종 mix/match를 통한 효율
                    극대화
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
  
      </div>
    </>
  );
}

export default SectionFeature;
