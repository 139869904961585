/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>주식회사 원컵</li>
                <li>서울특별시 서초구 강남대로 341, 8층</li>
                <li>경기도 성남시 분당구 판교로289번길 20 스타트업캠퍼스 1동 4층</li>
                {/* <li>
                  <a
                    href="https://www.creative-tim.com?ref=pkpr-black-footer"
                    target="_blank"
                    className="mr-1"
                  >
                    Creative Tim
                  </a>
                </li>
                <li>
                  <a
                    href="http://blog.creative-tim.com/?ref=pkpr-black-footer"
                    target="_blank"
                    className="mr-1"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.creative-tim.com/license?ref=pkpr-black-footer"
                    target="_blank"
                  >
                    Licenses
                  </a>
                </li> */}
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                Copyright by OneCup Co.,Ltd. All Rights Reserved.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
