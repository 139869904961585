import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContentEng() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="7">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/presentation-page/ipad-content-onecup-home.png")}
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    style={{ width: "auto" }}
                    src={require("assets/img/presentation-page/oncup-pro-app-1.png")}
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    style={{ width: "auto" }}
                    src={require("assets/img/presentation-page/oncup-pro-app-2.png")}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">OneCup PRO.</h3>
                  <h6 className="category">
                    Creating a Cup of Wealth
                    <br />
                    Creating Your Dream Cup
                  </h6>
                  <h5 className="description">
                    OneCup Pro is a customized real estate fractional investment
                    service based on customer preferences through the Asset
                    Future Value System.
                    <br />
                    It allows individuals to invest in landmark real estate,
                    from development to buying and leasing, in increments of
                    10,000 KRW within 3 minutes.
                    <br />
                    This service is designed to maximize profits with four asset
                    value prediction systems.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContentEng;
