import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function SectionSummaryEng() {
  return (
    <>
      <div className="section section-dark section-summary">
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-layout-11" />
                </div>
                <div className="description">
                  <h4 className="info-title">Vision</h4>
                  <p>
                    Fun Investment Life
                    <br />
                    Asset Value Maximization
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-tile-56" />
                </div>
                <div className="description">
                  <h4 className="info-title">Mission</h4>
                  <p>
                    Growing our investment portfolio
                    <br /> Borderless Economy
                    <br />
                    Reborn under-valued land and building
                    <br />
                    Creating our future better
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-paper" />
                </div>
                <div className="description">
                  <h4 className="info-title">Values</h4>
                  <p>
                    Dream Big <br />
                    Positive Impact
                    <br />
                    Integrity <br /> Performance
                    <br /> Responsibility
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummaryEng;
