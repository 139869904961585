import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div className="section section-components section-dark">
        <Row>
          <Col lg="6" md="12">
            <div className="image-container">
              <img
                alt="..."
                className="components-macbook"
                src={require("assets/img/presentation-page/laptop-value-add.png")}
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/img/presentation-page/single-value-add.png")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={require("assets/img/presentation-page/share-btn.png")}
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={require("assets/img/presentation-page/business-bangbea.jpg")}
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={require("assets/img/presentation-page/seoul.jpg")}
              />
              {/* <img
                alt="..."
                className="social-img"
                src={require("assets/img/presentation-page/social-row.png")}
              /> */}
              {/* <img
                alt="..."
                className="pin-btn-img"
                src={require("assets/img/presentation-page/pin-btn.png")}
              /> */}
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">
                초부유(超富裕) 포트폴리오
              </h3>
              <h6 className="category">
                ONECUP, No.1 in Creating Ultra-wealthy Portfolios
              </h6>
              <h5 className="description">
                원컵은 세상의 모든 자산을 증권화하여
                <br /> 누구나 손쉽게 투자할 수 있는 시대를 만들어 갑니다.
                <br />
                부동산 조각투자 서비스
                <br />
                '원컵프로(ONECUP.PRO)'를 통해 <br />
                공간의 활용 가치를 높이고, <br />
                장벽 없는 투자 프로세스로 <br />
                투자자의 자산 형성을 돕습니다.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
